import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const IndexPage = ({ data }) => {
  return (
    <Layout backgroundColor="#E67963">
      <SEO title="Home" />
      <div className="p-12 mb-8 rounded bg-orange-50">
        <h1 className="flex-grow p-4 m-0">
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
          >
            Welcome to Bento Kitchen!
          </Link>
        </h1>
        <p className="max-w-xl m-auto leading-relaxed text-gray-900 text-medium">
          The bento box is a single portion take-away lunchbox of Japanese
          origin; the Japanese equivalent to a Dutch ‘broodtrommel’.
          Traditionally, Japanese mothers make bentos for family members each
          and every day. At Bento Kitchen the heart of it is always a generous amount
          of whole grain rice, joined by a protein, an assortment of seasonal
          veggies and a fresh salad. There are endless varieties but the common
          ingredient is always love.
        </p>
        <p className="max-w-xl m-auto mt-2 leading-relaxed text-gray-900 text-medium">
          Our bento is primarily inspired by the Japanese kitchen, but every now
          and then we might surprise you with flavours from another continent
          too.
        </p>
        <p className="max-w-xl m-auto mt-2 leading-relaxed text-gray-900 text-medium">
          Interested in a healthy bento box for lunch? Each first Wednesday of
          the month it's Obento time. We deliver to your door, or pick up your
          portion at our kitchen around lunchtime. Please have a look at the
          menu and order yours through the following form:
        </p>
        <p className="max-w-xl m-auto mt-2 leading-relaxed text-gray-900 text-medium">
          Also, feel free to contact us if you are looking for a personalized
          bento catering for groups or events.
        </p>
        <div className="my-8">
          <Link
            className="p-4 font-semibold text-white transition-colors duration-200 ease-out rounded shadow-sm bg-blue-gray-800 hover:bg-blue-gray-700"
            to="/checkout"
          >
            Order now →
          </Link>
        </div>
      </div>

      <div className="m-auto max-w-3/4 gallery">
        {data.allFile.edges.map((image, index) => (
          <GatsbyImage
            key={index}
            alt=""
            className="transition-transform duration-100 ease-linear transform rounded hover:-translate-y-0.5 hover:shadow-xl"
            image={getImage(image.node)}
          />
        ))}
      </div>
    </Layout>
  )
}

export const data = graphql`
  query {
    allFile(
      filter: { relativeDirectory: { eq: "index_images" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default IndexPage
